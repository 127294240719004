let obj = {
    brChanger(text) {
        if (!text) return []
        const arr = [];
        const splitArr = text.split("\n"); // [`abc`, `def`]
        for (let i = 0; i < splitArr.length; i++) {
            arr.push(splitArr[i]);
            arr.push(<br/>);
        }
        arr.pop();
        return arr;
    }

}

export const currency = new Intl.NumberFormat(
    'ru-RU',
    {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }
).format

export default obj;