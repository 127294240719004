import React from 'react';
import styled from "styled-components";

const Banner_ = styled.div`
  display: block;
  margin-inline-start: -147px;
  margin-block-end: 10px;

  @media (min-width: 1301px) {
    margin-inline-start: 0;
    margin-block-end: 0;
  }
`;
const Banner = () => {
  return (
    <Banner_>
      <a href="http://www.sberbank.ru/businesscredit/partner/info?id=its96&site=https://www.its96.ru/&nocredit=true&utm_source=its96&utm_medium=banner&utm_campaign=credit_light" target="_blank" rel="noreferrer">
        <picture>
          <source media="(min-width: 1314px)" srcSet="/images/price/rassrochka_sber_1297x369@1x.png, /images/price/rassrochka_sber_1297x369@2x.png 2x" />
          <source media="(min-width: 1301px)" srcSet="/images/price/rassrochka_sber_1100x200@1x.png, /images/price/rassrochka_sber_1100x200@2x.png 2x" />
          <source media="(max-width: 1300px)" srcSet="/images/price/rassrochka_sber_720x260@1x.png, /images/price/rassrochka_sber_720x260@2x.png 2x" />
          <img src="/images/price/rassrochka_sber_720x260@1x.png" alt="price" style={{borderRadius: '24px'}} />
        </picture>
      </a>
    </Banner_>
  );
};

export default Banner;
