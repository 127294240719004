import React, { useEffect, useState } from 'react';
import styled from "styled-components";

import { Modal as Modal_Antd } from 'antd';
import Checkbox from "./Checkbox";

import close from '../../../../../assets/icons/calculate/close.svg';
import arrow from '../../../../../assets/icons/calculate/arrow.svg';

import './CalculateModal.scss';

export const InputReuse = (props) => {
    const {value, onChange, label, currency} = props;
    return (
        <div className="select_parent input">
            <span className="label">{label}</span>
            <div className="border"/>
            <div>
                <input
                    autoFocus="autoFocus"
                    value={value}
                    onChange={(e) => {
                        let str = e;
                        str.target.value = str.target.value.replace(/[^0-9.]/g, "");
                        onChange(str)
                    }}
                    placeholder={"0"}
                />
                <span className="currency">{currency}</span>
            </div>
        </div>
    )
};
export const SelectReuse = (props) => {
    const {
        flagOpen, currentField, flagField, currentArea, label,
        value, dropList, currency, areaValues, setAreaValues
    } = props;
    return (
        <div
            className={flagOpen ? "select_parent open" : "select_parent"}
            onClick={() => {
                let newObj = {...areaValues};
                newObj[currentArea][flagField] = !flagOpen;
                setAreaValues(newObj);
            }}
            onBlur={() => {
                let newObj = {...areaValues};
                newObj[currentArea][flagField] = false;
                setAreaValues(newObj);
            }}
            tabIndex={0}
        >
            <span className="label">{label}</span>
            <div className="border"/>
            <div style={{display: "flex"}}>
                <span className="value">{value}</span>
                <img src={arrow} className="arrow" alt="arrow"/>
                {currency &&
                    <div className="currency">{currency}</div>
                }
            </div>
            <div className={"select_drop"}>
                {dropList.map((elem, indx) =>
                    <div
                        className={elem === value ? "active" : ""}
                        onClick={() => {
                            let newObj = {...areaValues};
                            newObj[currentArea][currentField] = elem;
                            setAreaValues(newObj);
                        }}
                        key={indx}

                    >
                        {elem}
                    </div>
                )}
            </div>
        </div>
    )
};
export const Calculation = ({label, value, currency}) => {
    return (
        <div className="calculation">
            <span>{label}</span>
            <span>
                {value}
                {" м"}
                <sup>{currency}</sup>
            </span>
        </div>
    )
};
export const CalculationPack = ({label, value, border = ""}) => {
    return (
        <div className={border ? "calculation border" : "calculation"}>
            <span>{label}</span>
            <span>
                {value}
            </span>
        </div>
    )
};

const CalculateModal = ({open, setOpen}) => {
    const [areaValues, setAreaValues] = useState({
        wall: {
            active: true,
            type: "Пенополистирол",
            widthInsulation: "0.05",
            allWidth: null,
            height: null,
            select_1: false,
            select_2: false,
        },
        floor: {
            active: false,
            type: "Пенополистирол",
            widthInsulation: "0.05",
            length: null,
            width: null,
            select_1: false,
            select_2: false,
        },
        roof: {
            active: false,
            type: "Пенополистирол",
            widthInsulation: "0.05",
            length: null,
            width: null,
            select_1: false,
            select_2: false,
        },
        fence: {
            active: false,
            type: "C-8",
            length: null,
            height: null,
            select_1: false,
        },
    })
    const [resultCalculate, setResultCalculate] = useState([]);

    let currentSelectArray = (type) => {
        if (type === "Пеноплэкс")
            return ["0.02", "0.03", "0.05", "0.1", "0.15"];
        else
            return ["0.05", "0.1", "0.15", "0.2"]
    }
    let calcCurrentCountPack = (m3, matherialType, widthInsulation, m2) => {
        const packVolume = 0.288; // объем пачки Базальтовый утеплитель
        const rollVolume = 0.66978; // объем рулона
        const listVolumeExt = 1.185; // Экструдированный лист константа 1
        const listVolumeExt2 = 0.585; // Экструдированный лист константа 2
        const listVolumePpc = 1.82; // Пенополистирол лист константа 1
        const listVolumePpc2 = 0.91; // Пенополистирол лист константа 2

        switch (matherialType) {
            //  Расчёт количества листов пеноплэкса изменить на =S/1.185/0.585

            case "Пенополистирол":
                return {
                    label: "Количество листов пенополистирола",
                    value: Math.ceil(m2 / listVolumePpc / listVolumePpc2)
                };
            case "Пеноплэкс":
                return {
                    label: "Количество листов пеноплэкса",
                    value: Math.ceil(m2 / listVolumeExt / listVolumeExt2)
                };
            case "Базальтовая плита":
                return {
                    label: "Количество пачек базальта",
                    value: Math.ceil(m3 / packVolume)
                };
            case "Изовер \"Теплый дом\"":
                return {
                    label: "Количество рулонов изовера \"Теплый Дом\"",
                    value: Math.ceil(m3 / rollVolume)
                };
            default:
                return {
                    label: "null",
                    value: 0
                };
        }
    }
    let calculate = () => {
        let result = [];
        setResultCalculate([]);

        // Общая площадь утепления стен (м2)
        let m2Wall = +(areaValues.wall.allWidth * areaValues.wall.height).toFixed(1);
        // Общий объем утепления стен (м3)
        let m3Wall = +(m2Wall * areaValues.wall.widthInsulation).toFixed(1);
        // Количество листов/пачек/рулонов на стены
        let countWall = calcCurrentCountPack(m3Wall, areaValues.wall.type, areaValues.wall.widthInsulation, m2Wall);

        // Общая площадь утепления пола (м2)
        let m2Floor = +(areaValues.floor.length * areaValues.floor.width).toFixed(1);
        // Общий объем утеплителя на пол (м3)
        let m3Floor = +(m2Floor * areaValues.floor.widthInsulation).toFixed(1);
        // Количество листов/пачек/рулонов на пол
        let countFloor = calcCurrentCountPack(m3Floor, areaValues.floor.type, areaValues.floor.widthInsulation, m2Floor);

        // Общая площадь утепления потолка (м2)
        let m2Roof = +(areaValues.roof.length * areaValues.roof.width).toFixed(1);
        // Общий объем утеплителя на потолок (м3)
        let m3Roof = +(m2Roof * areaValues.roof.widthInsulation).toFixed(1);
        // Количество листов/пачек/рулонов на потолок
        let countRoof = calcCurrentCountPack(m3Roof, areaValues.roof.type, areaValues.roof.widthInsulation, m2Roof);

        // Общая площадь забора (м2)
        let objValuesL = {
            "C-8": 1.15,
            "C-10": 1.14,
            "C-13": 1.15,
            "МП-20": 1.1,
        }
        let objValuesKv = {
            "C-8": 1.2,
            "C-10": 1.19,
            "C-13": 1.17,
            "МП-20": 1.15,
        }
        let m2Fence = +(areaValues.fence.length * areaValues.fence.height).toFixed(1);
        let l = Math.ceil(areaValues.fence.length / objValuesL[areaValues.fence.type]);
        let kv = (areaValues.fence.height * objValuesKv[areaValues.fence.type] * l).toFixed(2);

        if (areaValues.wall.allWidth && areaValues.wall.height) {
            result = [
                ...result,
                <div className="label_section">Стены</div>,
                <Calculation
                    label={"Общая площадь утепления"}
                    value={m2Wall}
                    currency={"2"}
                    key={1}
                />,
                <Calculation
                    label={"Общий объем утепления"}
                    value={m3Wall}
                    currency={"3"}
                    key={2}
                />,
                <CalculationPack
                    label={`${countWall.label}`}
                    value={countWall.value}
                    key={3}
                    border={true}
                />,
            ];
        }
        if (areaValues.floor.length && areaValues.floor.width) {
            result = [
                ...result,
                <div className="label_section">Пол</div>,
                <Calculation
                    label={"Общая площадь утепления"}
                    value={m2Floor}
                    currency={"2"}
                    key={4}
                />,
                <Calculation
                    label={"Общий объем утепления"}
                    value={m3Floor}
                    currency={"3"}
                    key={5}
                />,
                <CalculationPack
                    label={`${countFloor.label}`}
                    value={countFloor.value}
                    key={6}
                    border={true}
                />
            ];
        }
        if (areaValues.roof.length && areaValues.roof.width) {
            result = [
                ...result,
                <div className="label_section">Потолок</div>,
                <Calculation
                    label={"Общая площадь утепления"}
                    value={m2Roof}
                    currency={"2"}
                    key={7}
                />,
                <Calculation
                    label={"Общий объем утепления"}
                    value={m3Roof}
                    currency={"3"}
                    key={8}
                />,
                <CalculationPack
                    label={`${countRoof.label}`}
                    value={countRoof.value}
                    key={9}
                    border={true}
                />
            ];
        }
        if (areaValues.fence.length && areaValues.fence.height) {
            result = [
                ...result,
                <div className="label_section">Забор</div>,
                <CalculationPack
                    label={`Обшивка забора общая площадь`}
                    value={m2Fence}
                    key={10}
                />,
                <CalculationPack
                    label={`Количество листов забора`}
                    value={l}
                    key={11}
                />,
                <CalculationPack
                    label={`Площадь необходимого материала`}
                    value={kv}
                    key={12}
                    border={true}
                />
            ];
        }
        setResultCalculate(result);
    };
    let clearData = () => {
        let newData = {...areaValues};
        setAreaValues(newData);
        setResultCalculate([]);
    };

    useEffect(() => {
        let newValues = {...areaValues};
        newValues.wall.widthInsulation = currentSelectArray(areaValues.wall.type)[0];
        setAreaValues(newValues);
    }, [areaValues.wall.type])
    useEffect(() => {
        let newValues = {...areaValues};
        newValues.floor.widthInsulation = currentSelectArray(areaValues.floor.type)[0];
        setAreaValues(newValues);
    }, [areaValues.floor.type])
    useEffect(() => {
        let newValues = {...areaValues};
        newValues.roof.widthInsulation = currentSelectArray(areaValues.roof.type)[0];
        setAreaValues(newValues);
    }, [areaValues.roof.type])
    return (
        <Modal_Antd
            open={open}
            footer={null}
            className={"modal_calculate"}
            maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.70)"}}
        >
            <div className="h1" id={"CHECK"}>
                <div className="empty"/>
                <span>Онлайн калькулятор</span>
                <img
                    className="close"
                    src={close}
                    onClick={() => setOpen()}
                    alt="close"
                />
            </div>
            <div className="info_text">Калькулятор расчета количества утеплителя для стен и фундаментов</div>
            <div className="filters">
                <Checkbox
                    value={areaValues.wall.active}
                    setValue={(e) => {
                        let newObj = {...areaValues};
                        newObj["wall"].active = e;
                        newObj["wall"].allWidth = null;
                        newObj["wall"].height = null;
                        setAreaValues(newObj);
                    }}
                    label={"Площадь стен"}
                    id={"wall"}
                />
                <Checkbox
                    value={areaValues.floor.active}
                    setValue={(e) => {
                        let newObj = {...areaValues};
                        newObj["floor"].active = e;
                        newObj["floor"].length = null;
                        newObj["floor"].width = null;
                        setAreaValues(newObj);
                    }}
                    label={"Площадь пола"}
                    id={"floor"}
                />
                <Checkbox
                    value={areaValues.roof.active}
                    setValue={(e) => {
                        let newObj = {...areaValues};
                        newObj["roof"].active = e;
                        newObj["roof"].length = null;
                        newObj["roof"].width = null;
                        setAreaValues(newObj);
                    }}
                    label={"Площадь потолка"}
                    id={"roof"}
                />
                <Checkbox
                    value={areaValues.fence.active}
                    setValue={(e) => {
                        let newObj = {...areaValues};
                        newObj["fence"].active = e;
                        newObj["fence"].length = null;
                        newObj["fence"].width = null;
                        setAreaValues(newObj);
                    }}
                    label={"Расчет забора"}
                    id={"fence"}
                />
            </div>
            {areaValues.wall.active &&
                <div className="block_area">
                    <div className="h2">Площадь стен</div>
                    <div className="select_row">
                        <SelectReuse
                            currentArea={"wall"}
                            currentField={"type"}
                            label={"Вид утеплителя"}
                            value={areaValues.wall.type}
                            dropList={["Пенополистирол", "Пеноплэкс", "Базальтовая плита", "Изовер \"Теплый дом\""]}
                            areaValues={areaValues}
                            setAreaValues={setAreaValues}
                            flagOpen={areaValues.wall.select_1}
                            flagField={"select_1"}
                        />
                        <SelectReuse
                            currentArea={"wall"}
                            currentField={"widthInsulation"}
                            label={"Толщина утеплителя"}
                            value={areaValues.wall.widthInsulation}
                            dropList={currentSelectArray(areaValues.wall.type)}
                            areaValues={areaValues}
                            setAreaValues={setAreaValues}
                            flagOpen={areaValues.wall.select_2}
                            flagField={"select_2"}
                            currency={"м"}
                        />
                    </div>
                    <div className="select_row">
                        <InputReuse
                            label={"Общая длина всех стен"}
                            value={areaValues.wall.allWidth}
                            onChange={(e) => {
                                let newObj = {...areaValues};
                                newObj.wall["allWidth"] = e.target.value;
                                setAreaValues(newObj);
                            }}
                            currency={"м"}
                        />
                        <InputReuse
                            label={"Высота стен"}
                            value={areaValues.wall.height}
                            onChange={(e) => {
                                let newObj = {...areaValues};
                                newObj.wall["height"] = e.target.value;
                                setAreaValues(newObj);
                            }}
                            currency={"м"}
                        />
                    </div>
                </div>
            }
            {areaValues.floor.active &&
                <div className="block_area">
                    <div className="h2">Площадь пола</div>
                    <div className="select_row">
                        <SelectReuse
                            currentArea={"floor"}
                            currentField={"type"}
                            label={"Вид утеплителя"}
                            value={areaValues.floor.type}
                            dropList={["Пенополистирол", "Пеноплэкс", "Базальтовая плита", "Изовер \"Теплый дом\""]}
                            areaValues={areaValues}
                            setAreaValues={setAreaValues}
                            flagOpen={areaValues.floor.select_1}
                            flagField={"select_1"}
                        />
                        <SelectReuse
                            currentArea={"floor"}
                            currentField={"widthInsulation"}
                            label={"Толщина утеплителя"}
                            value={areaValues.floor.widthInsulation}
                            dropList={currentSelectArray(areaValues.floor.type)}
                            areaValues={areaValues}
                            setAreaValues={setAreaValues}
                            flagOpen={areaValues.floor.select_2}
                            flagField={"select_2"}
                            currency={"м"}
                        />
                    </div>
                    <div className="select_row">
                        <InputReuse
                            label={"Длина"}
                            value={areaValues.floor.length}
                            onChange={(e) => {
                                let newObj = {...areaValues};
                                newObj.floor["length"] = e.target.value;
                                setAreaValues(newObj);
                            }}
                            currency={"м"}
                        />
                        <InputReuse
                            label={"Ширина"}
                            value={areaValues.floor.width}
                            onChange={(e) => {
                                let newObj = {...areaValues};
                                newObj.floor["width"] = e.target.value;
                                setAreaValues(newObj);
                            }}
                            currency={"м"}
                        />
                    </div>
                </div>
            }
            {areaValues.roof.active &&
                <div className="block_area">
                    <div className="h2">Площадь потолка</div>
                    <div className="select_row">
                        <SelectReuse
                            currentArea={"roof"}
                            currentField={"type"}
                            label={"Вид утеплителя"}
                            value={areaValues.roof.type}
                            dropList={["Пенополистирол", "Пеноплэкс", "Базальтовая плита", "Изовер \"Теплый дом\""]}
                            areaValues={areaValues}
                            setAreaValues={setAreaValues}
                            flagOpen={areaValues.roof.select_1}
                            flagField={"select_1"}
                        />
                        <SelectReuse
                            currentArea={"roof"}
                            currentField={"widthInsulation"}
                            label={"Толщина утеплителя"}
                            value={areaValues.roof.widthInsulation}
                            dropList={currentSelectArray(areaValues.roof.type)}
                            areaValues={areaValues}
                            setAreaValues={setAreaValues}
                            flagOpen={areaValues.roof.select_2}
                            flagField={"select_2"}
                            currency={"м"}
                        />
                    </div>
                    <div className="select_row">
                        <InputReuse
                            label={"Длина"}
                            value={areaValues.roof.length}
                            onChange={(e) => {
                                let newObj = {...areaValues};
                                newObj.roof["length"] = e.target.value;
                                setAreaValues(newObj);
                            }}
                            currency={"м"}
                        />
                        <InputReuse
                            label={"Ширина"}
                            value={areaValues.roof.width}
                            onChange={(e) => {
                                let newObj = {...areaValues};
                                newObj.roof["width"] = e.target.value;
                                setAreaValues(newObj);
                            }}
                            currency={"м"}
                        />
                    </div>
                </div>
            }
            {areaValues.fence.active &&
                <div className="block_area">
                    <div className="h2">Площадь забора</div>
                    <div className="select_row">
                        <SelectReuse
                            currentArea={"fence"}
                            currentField={"type"}
                            label={"Обшивка забора"}
                            value={areaValues.fence.type}
                            dropList={["C-8", "C-10", "C-13", "МП-20"]}
                            areaValues={areaValues}
                            setAreaValues={setAreaValues}
                            flagOpen={areaValues.fence.select_1}
                            flagField={"select_1"}
                        />
                    </div>
                    <div className="select_row">
                        <InputReuse
                            label={"Длина"}
                            value={areaValues.fence.length}
                            onChange={(e) => {
                                let newObj = {...areaValues};
                                newObj.fence["length"] = e.target.value;
                                setAreaValues(newObj);
                            }}
                            currency={"м"}
                        />
                        <InputReuse
                            label={"Высота забора"}
                            value={areaValues.fence.height}
                            onChange={(e) => {
                                let newObj = {...areaValues};
                                newObj.fence["height"] = e.target.value;
                                setAreaValues(newObj);
                            }}
                            currency={"м"}
                        />
                    </div>
                </div>
            }
            {resultCalculate.length > 0 &&
                <div className="result">
                    <div className="h2">Расчеты</div>
                    {resultCalculate.map((elem) => elem)}
                </div>
            }
            <div className="group_buttons">
                <div
                    className="btn_result"
                    onClick={() => {
                        setResultCalculate([]);
                        calculate();
                    }}
                >
                <span>
                    {resultCalculate.length > 0
                        ? "Рассчитать заново"
                        : "Рассчитать"
                    }
                </span>
                </div>
                {resultCalculate.length > 0 &&
                    <div
                        className="btn_result clear"
                        onClick={() => clearData()}
                    >
                        <span>Очистить результат</span>
                    </div>
                }
            </div>
        </Modal_Antd>
    );
};

export default CalculateModal;