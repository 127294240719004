import React, { useEffect, useState }  from 'react';
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Slider from "react-slick";

import requests from "../../axios/requests";
import { History } from "../News/News";
import helpers, { currency } from "../../utils/helpers";
import arrow from '../../assets/icons/news/arrow.svg';

import { Modal as Modal_Antd } from 'antd';
import './ModalGallery.scss';
import close from '../../assets/icons/calculate/close.svg';
import arrow_accent from '../../assets/icons/news/arrow_accent.svg';

const ProductInfo_ = styled.div`
  background: #F3F3F3;
  padding: 15px 15px 0 15px;

  .product_page {
    display: flex;
    gap: 40px;
    padding: 50px 0;

    @media (max-width: 920px) {
        flex-direction: column;
    }
  }

  .product_page_left_img {
    flex: 1 1 25%;
    width: 25%;
    border-radius: 24px;

    @media (max-width: 920px) {
        flex: 1 1 100%;
        width: 100%;
    }

    img {
      cursor: pointer;
    }
  }

  .product_page_right {
    flex: 1 1 75%;

    @media (max-width: 920px) {
        flex: 1 1 100%;
    }
  }

  .h1 {
    font-family: RfdBold, sans-serif;
    font-size: 38px;
    font-weight: 700;
    line-height: 46px;
  }

  .price {
    margin-top: 20px;
    font-family: RfdBold, sans-serif;
    font-size: 38px;
    font-weight: 700;
    line-height: 46px;
    margin-bottom: 10px;
  }

  .slick-slider {
    margin: 0 auto;
    max-width: 1300px;
    width: 100%;
    @media (max-width: 1320px) {
      margin-left: 0;
    }
  }

  .slick-track:not(:has(.slick-slide:not(.slick-cloned)[data-index="3"])) .slick-cloned {
    display: none !important;
  }

  .slider-gallery .slick-track {
    display: flex;
    gap: 10px;
  }

  .slick-list {
    /*padding: 0 20px !important;*/
    @media (max-width: 728px) {
      padding: 0 !important;
    }
  }

  .slick-slide img {
    border-radius: 24px;
  }

  .nav_slider {
    margin: 0 auto;
    max-width: 1300px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .arrows {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      img {
        width: 50px;
        height: 50px;
        cursor: pointer;

        &:last-child {
          margin-left: 6px;
          transform: rotate(180deg);
        }
      }
    }

    @media (max-width: 1320px) {
      margin-left: 0;
    }
  }
`;

export const ProductInfo = () => {
    const params = useParams();
    
    const sliderRef = React.useRef(null);
    const sliderNavRef = React.useRef(null);
    const sliderModalRef = React.useRef(null);
    const sliderModalNavRef = React.useRef(null);
    const settings = {
      touchMove: window.innerWidth <= 728,
      centerPadding: window.innerWidth > 1205 && '70px',
      maxWidth: 1300,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      arrows: false,
    }
    const settingsNav = {
      touchMove: window.innerWidth <= 728,
      centerPadding: window.innerWidth > 1205 && '70px',
      maxWidth: 1300,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
    }

    const [product, setProduct] = useState({});
    const [open, setOpen] = useState(false);

    const syncSliders = () => {
      const state = sliderRef?.current?.innerSlider?.state
      const index = state?.currentSlide
      sliderNavRef?.current?.slickGoTo(index)
      sliderModalRef?.current?.slickGoTo(index)
      sliderModalNavRef?.current?.slickGoTo(index)
    }

    useEffect(() => {
        if (params.id)
            requests.fetch_id_product(params.id)
                .then(res => (
                  setProduct(res.data)
                ))
                .catch(err => console.error(err))
        else setProduct({});
    }, [params.id])

    return (
        <ProductInfo_>
            <div className="container">
                <History parts={[
                  { path: '/', title: 'Главная' },
                  { path: '/catalog', title: "Каталог" },
                  { path: `/catalog/${product?.category?.id}`, title: product?.category?.name },
                  { path: `/catalog/${product?.category?.id}/${product?.subcategory?.id}`, title: product?.subcategory?.name },
                  { title: product?.name }
                ]} />
                <div className="product_page">
                    <div className="product_page_left_img">
                        <Slider ref={sliderRef} asNavFor={sliderNavRef?.current} afterChange={() => syncSliders()} {...settings}>
                            {product?.images?.map((img, index) =>
                                <img
                                    key={index}
                                    src={img.image}
                                    alt="product"
                                    onClick={() => (
                                      setOpen(true),
                                      syncSliders()
                                    )}
                                />
                            )}
                        </Slider>
                        <Slider ref={sliderNavRef} asNavFor={sliderRef?.current} {...settingsNav} className="slider-gallery">
                            {product?.images?.map((img, index) =>
                                <img
                                    key={index}
                                    src={img.image}
                                    alt="product"
                                />
                            )}
                        </Slider>
                        <div className="nav_slider">
                            <div className="arrows">
                                <img src={arrow} onClick={() => sliderRef?.current?.slickPrev()}/>
                                <img src={arrow} onClick={() => sliderRef?.current?.slickNext()}/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="product_page_right">
                        <div className="h1">{helpers.brChanger(product?.name)}</div>
                        <div className="price">{currency(product?.price)} / {product?.type}</div>
                        <p>{helpers.brChanger(product?.description)}</p>
                    </div>
                </div>
            </div>
            <Modal_Antd
                open={open}
                footer={null}
                className={"modal_gallery"}
                maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.70)"}}
            >
                <img
                    className="close"
                    src={close}
                    onClick={() => setOpen(false)}
                    alt="close"
                />
                <div className="content">
                    <Slider ref={sliderModalRef} asNavFor={sliderModalNavRef?.current} onInit={() => syncSliders()} {...settings}>
                        {product?.images?.map((img, index) =>
                            <img
                                key={index}
                                src={img.image}
                                alt="product"
                            />
                        )}
                    </Slider>
                    <Slider ref={sliderModalNavRef} asNavFor={sliderModalRef?.current} onInit={() => syncSliders()} {...settingsNav} className="slider-gallery">
                        {product?.images?.map((img, index) =>
                            <img
                                key={index}
                                src={img.image}
                                alt="product"
                            />
                        )}
                    </Slider>
                    <img src={arrow_accent} className="nav-button" onClick={() => (
                      sliderModalRef?.current?.slickPrev(),
                      sliderRef?.current?.slickPrev()
                    )}/>
                    <img src={arrow_accent} className="nav-button" onClick={() => (
                      sliderModalRef?.current?.slickNext(),
                      sliderRef?.current?.slickNext()
                    )}/>
                </div>
            </Modal_Antd>
        </ProductInfo_>
    );
};
