import React from 'react';
import styled from "styled-components";

import { ListElem } from "./ListElem";

const Catalog_ = styled.div`
  background: #F3F3F3;
  padding: 15px 15px 0 15px;
`;
const H1_ = styled.div`
  margin: 0 auto 60px auto;
  width: fit-content;
  font-family: RfdBold, sans-serif;
  font-weight: 700;
  font-size: 38px;
  line-height: 46px;
  color: #1D1D1D;
  
  @media (max-width: 720px) {
    margin: 0 auto 30px auto;
    font-size: 22px;
    line-height: 26px;
  }
`;
const Content_ = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  min-height: 600px;
  @media (max-width: 1315px) {
    justify-content: center;
  }
`;

export const CatalogList = ({history, title, data, currentPage, pagination, handleOpen}) => {
    return (
        <Catalog_>
            <div className="container">
                { history }
                <H1_>{ title }</H1_>
                <Content_>
                    {
                        data?.length > 0 && data.map((elem) => 
                            <ListElem
                                src={elem.photo}
                                label={elem.name}
                                key={elem.id}
                                size={elem.size}
                                page={currentPage}
                                id={elem.id}
                                handleOpen={() => handleOpen(elem.id)}
                            />
                        )
                    }
                </Content_>
                { pagination }
            </div>
        </Catalog_>
    )
}
