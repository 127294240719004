import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import requests from "../../axios/requests";

import { History } from "../News/News";
import { CatalogList } from "./components/CatalogList";

export const CatalogCategories = ({scrollToTop}) => {
    const navigation = useNavigate();

    const [data, setData] = useState({});

    useEffect(() => {
        requests.fetchCatalog()
        .then(res => {
            res.data?.forEach(
                elem => (
                    elem.size = "small"
                )
            );
            setData(res.data)
        })
        .catch(err => console.error(err))
        .finally(scrollToTop)
    }, [scrollToTop]);

    return (
        <CatalogList
            history={<History parts={[
                { path: '/', title: 'Главная' },
                { title: "Каталог" }
            ]} />}
            title="Каталог"
            data={data}
            currentPage={1}
            handleOpen={(id) => navigation(`/catalog/${id}`)}
        />
    );
};
